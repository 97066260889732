
.main-wrapper {
  padding-top: 56px;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  justify-content: flex-start;
  align-items: center;
}

.home-inner {
  width: 90%;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;

  justify-content: flex-start;
}

.auth-inner {
  width: 90%;
  max-width: 500px;
}

textarea {
  font-family: 'Roboto', sans-serif;
  font-size: 16px;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.87);
}
