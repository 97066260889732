@import url('https://fonts.googleapis.com/css?family=Roboto:400,500,600,700,800');

* {
  box-sizing: border-box;
}

body {
  min-height: 100vh;
  font-weight: 400;
  font-family: 'Roboto', sans-serif;
}

h1,h2,h3,h4,h5,h6,label,span {
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
}

body, html, .App, #root, .main-wrapper {
  width: 100%;
  height: 100%;

  background: #adb5bd;
}
