.logs-container {
    padding-top: 2em;
    /*height: 20em;*/
    justify-self: stretch;
    align-self: center;
    width: 98%;
    /*overflow: scroll;*/
}

.log-item {
    margin-bottom: 1em;
    padding: 1em;
    background: white;
    filter: drop-shadow(0px 1px 4px rgba(0, 0, 0, 0.3));
    border-radius: 10px;
}

.log-item .log-item-header {
    font-size: larger;
    color: #7a7a7a;
}

.log-item .log-item-text {
    color: black;
    white-space: pre-wrap;
    overflow: hidden;
}

.log-item .timeinfo {
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    margin-top: 0.3em;
    align-items: center;
    justify-items: start;
}

.timeinfo .relative-date {
    font-size: smaller;
    color: #94c4ec;
}

.timeinfo .absolute-date {
    font-size: smaller;
    color: #7a7a7a;
}
