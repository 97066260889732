.datetime-container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    width: 0;
    min-width: 100%;
}

.datetime-container .form-row {
    display: flex;
    flex-direction: row;
    align-items: start;
    justify-content: start;
    margin-bottom: 1em;
}

.datetime-elem {
    margin-left: 16px;
}
