.log-form {
    position: fixed;
    top: 56px;
    right: 0;
    left: 0;
    z-index: 1050;
    width: 90%;
    margin: auto;
    padding-top: 10px;
    padding-bottom: 5px;

    background: #adb5bd;
}

.show-div {
    display: flex;
}

.hide-div {
    display: none;
}