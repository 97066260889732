
.m-grid {
    width: 80%;

    justify-self: stretch;
    align-self: center;
}

.m-row {
    display: flex;
    flex-direction: row;
}
.m-row-container {
    display: flex;
    flex-direction: row;
}

.m-row-new-month {
    border-bottom: #444444 1px dotted;
}

.m-row-weekend {
    background: #a1afbb;
}

.m-row-break {
    margin-bottom: 20px;
}

.m-header {
    height: 30px;
    width: 30px;

    text-align: center;
    font-size: larger;
}

.m-row-label {
    height: 30px;
    width: 100px;

    line-height: 30px;
    text-align: right;
    padding-right: 10px;
}

.m-cell-container {
    height: 30px;
    width: 30px;

    border: gray 1px solid;
    border-radius: 5px;

    overflow: hidden;

    margin: 1px;
}

.m-cell {
    height: 100%;
    width: 100%;
}

.m-0 {
}

.m-25 {
    background: #444444;
    opacity: 0.25;
}
.m-50 {
    background: #444444;
    opacity: 0.5;
}

.m-75 {
    background: #444444;
    opacity: 0.75;
}

.m-100 {
    background: #444444;
    opacity: 1;
}